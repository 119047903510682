import { useAuthStore } from '@/stores/auth';

export default defineNuxtRouteMiddleware(() => {
  const config = useRuntimeConfig();
  const { loggedIn } = storeToRefs(useAuthStore());

  if (loggedIn.value) {
    return navigateTo(
      {
        path: config.public.homePageUrl
      },
      { replace: true }
    );
  }
});
